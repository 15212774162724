@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "worksans";
  font-style: normal;
  font-weight: 100 900;
  src: url("../fonts/worksans.ttf") format("truetype");
}
