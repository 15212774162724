.layer-children {
  /* will-change: transform, opacity, height; */
  overflow: hidden;
}

.layer {
  position: relative;
  padding: 4px 0px 0px 0px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  vertical-align: middle;
  color: white;
  fill: white;
}

.layers::-webkit-scrollbar {
  width: 6px;
}
.layers::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
}
.layers::-webkit-scrollbar-thumb {
  background-color: #a0aec0;
  @apply rounded-md;
}
